import React, { Component } from "react";

//Import Breadcrumb
import RCDisplayPageMem from "../../components/Common/RCDisplayPageMem";
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { Card, CardBody, Col, Container, Row, Table, Button } from "reactstrap";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  toggleapprove
  
} from "../../store/functions";

class pageList_CashLoan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      isProgress:true,
      isEdit : false,
      gridDatamemberlog :[],
      isDelete : false ,
      roleid : 0,
      confirmapprove_alert : false
     
    };
    this.obj = this;
    this.breadCrumbTitle = "Memberships";
    this.breadCrumbItem = "Members";
    this.modalTitle = "Memberships";
    this.rtPage_Add = "add-membership";
    this.rtPage_Edit = "/masters-membershipmodify";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/PendingMonthlyLoan";

    //Event Binding
   
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
   
    this.btnApprove  = this.btnApprove.bind(this);
  }


 
  componentDidMount() {
    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", "Approve Cash Loan")
    const obj = JSON.parse(localStorage.getItem("authUser"));
    Fn_FillListData(this.obj, "gridDatamember", this.API_URL + "/Id/0");
  }

  btnApprove(formData){
      this.setState({confirmapprove_alert : false})
    Fn_FillListData(this.obj, "ApproveLoan", API_WEB_URLS.MASTER + "/0/token/ApproveLoan" + "/Id/"+formData.Id);
    Fn_FillListData(this.obj, "gridDatamember", this.API_URL + "/Id/0");

  }
  



  btnDelete_onClick(formData) {
    this.setState({confirm_alert : false})
    Fn_FillListData(this.obj, "RejectLoan", API_WEB_URLS.MASTER + "/0/token/RejectLoan" + "/Id/"+formData.Id);
    Fn_FillListData(this.obj, "gridDatamember", this.API_URL + "/Id/0");

  }
  renderGridHeader() {
    return (
      <>
      
        <th>Date</th>
        
        <th>GIN</th>
        <th>Full Name</th>
        <th>Loan Limit</th>
        <th>Loan Amount</th>
        <th>Interest</th>
        <th>Amount to Pay</th>

      </>
    );
  }

  


  renderGridBody(formData) {

   

    return (
      <>
        <td>{formData.OpeningDate}</td>
        <td>{formData.GIN}</td>
        <td>{formData.FullName}</td>
        <td>{formData.NetLoanLimit}</td>
        <td>{formData.LoanAmountRequired}</td>
        <td>{formData.APR}</td>
        <td>{formData.TotalAmountPayable}</td>
      
       
      </>
    );
  }


  



  renderModalBody(selectedFormData) {
 
   
  }
  render() {
    return (
<div className="page-content">
      <RCDisplayPageMem
        //page header paramaters
        Isbreadcrumb = {true}
        breadCrumbTitle={this.breadCrumbTitle}
        breadcrumbItem={this.breadCrumbItem}
        obj={this.obj}
        //column paramaters
        isSearchBox={true}
        isSNo={true}
        isCheckBox={false}
        isViewDetails={false}
        isProgress = {this.state.isProgress}
        //grid paramaters
        gridData={this.state.gridDatamember}
        gridHeader={this.renderGridHeader}
        gridBody={this.renderGridBody}
        btnAdd_onClick={this.btnAdd_onClick}
        btnEdit_onClick={this.btnEdit_onClick}
        printInvoice = {this.printInvoice}
        //delete link parameters
        confirm_alert={this.state.confirm_alert}
        confirmapprove_alert={this.state.confirmapprove_alert}
        success_dlg={this.state.success_dlg}
        dynamic_title={this.state.dynamic_title}
        dynamic_description={this.state.dynamic_description}
        toggleDeleteConfirm={toggleDeleteConfirm}
        toggleDeleteSuccess={toggleDeleteSuccess}
        toggleapprove={toggleapprove}
        btnDelete_onClick={this.btnDelete_onClick}
        btnApprove={this.btnApprove}
        searchmember = {this.searchmember}
        //modal paramaters
        isOpenModal={this.state.modal}
        modalTitle={this.modalTitle}
        selectedFormData={this.state.selectedFormData}
        modalBody={this.renderModalBody}
        togglemodal={togglemodal}
        //user rights
        isAdd={false}
        isEdit={false}
        isDelete={true}
        isApprove={true}
      ></RCDisplayPageMem>
</div>

      
    );
  }
}
export default compose(container)(pageList_CashLoan);
