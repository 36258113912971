import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";

class NewLoginScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      selectedImage: 0,
      images: [
        "/path/to/image1.jpg",
        "/path/to/image2.jpg", 
        "/path/to/image3.jpg",
        "/path/to/image4.jpg",
        "/path/to/image5.jpg"
      ]
    };
  }

  handleImageSelect = (index) => {
    this.setState({ selectedImage: index });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    // Add login logic here
  }

  render() {
    return (
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <CardBody className="p-0">
                  <Row className="g-0">
                    <Col md={6}>
                      <div className="p-4">
                        <div className="text-center mt-2">
                          <h3 className="text-primary">CREATE YOUR ACCOUNT WITH</h3>
                          <h4 className="text-muted">MISS MALINA</h4>
                        </div>

                        <div className="mt-4">
                          <form className="form-horizontal" onSubmit={this.handleSubmit}>
                            <div className="mb-3">
                              <input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                value={this.state.email}
                                onChange={(e) => this.setState({email: e.target.value})}
                              />
                            </div>

                            <div className="mb-3">
                              <input
                                name="password"
                                className="form-control"
                                placeholder="Enter password"
                                type="password"
                                value={this.state.password}
                                onChange={(e) => this.setState({password: e.target.value})}
                              />
                            </div>

                            <div className="mt-4 d-grid">
                              <Button color="primary" type="submit">
                                Create Account
                              </Button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Col>

                    <Col md={6} className="position-relative">
                      <div 
                        className="bg-light h-100"
                        style={{
                          backgroundImage: `url(${this.state.images[this.state.selectedImage]})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center'
                        }}
                      />
                      <div className="position-absolute bottom-0 start-0 p-3 w-100">
                        <div className="d-flex gap-2">
                          {this.state.images.map((img, index) => (
                            <div
                              key={index}
                              onClick={() => this.handleImageSelect(index)}
                              className={`thumbnail-preview ${this.state.selectedImage === index ? 'active' : ''}`}
                              style={{
                                width: '40px',
                                height: '40px',
                                backgroundImage: `url(${img})`,
                                backgroundSize: 'cover',
                                cursor: 'pointer',
                                border: this.state.selectedImage === index ? '2px solid #556ee6' : 'none'
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default NewLoginScreen;
