import React, { Component , useRef} from "react";
import PropTypes from 'prop-types';

import "./newheader.css";
import { connect } from "react-redux";

import { Link } from "react-router-dom";

import save from './static/img/save.png';
import news from './static/img/new.png';
import print from './static/img/print.png';
import reset from './static/img/reset.png';
import send from './static/img/send.png';
import exports from './static/img/export.png';


// Redux Store
import { logoutUser, toggleRightSidebar } from "../../store/actions";

//i18n
import { withTranslation } from "react-i18next";

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isSearch: false,
      open: false,
      position: 'right',
      activemmId : 3,
      PageName : 'DASHBOARD',
      windowwidth : window.innerWidth
    }
    this.toggleMenu = this.toggleMenu.bind(this)
    this.toggleFullscreen = this.toggleFullscreen.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.toggleRightDrawer = this.toggleRightDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
    this.onDrawerClose = this.onDrawerClose.bind(this);

  }


  
  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch })
  }

  
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack()
  }

  /**
   * Toggles the sidebar
   */
  toggleRightDrawer() {
    this.setState({ position: 'right' });
    this.setState({ open: !this.state.open });
  }
  closeDrawer() {
    this.setState({ open: false });
  }
  onDrawerClose() {
    this.setState({ open: false });
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  componentDidMount () {
    this.setState({activemmId : localStorage.getItem("activemmId")});
    
  setTimeout(function() { //Start the timer
    this.setState({ PageName: localStorage.getItem("PageName").toUpperCase() });  
}.bind(this), 1000)


if (localStorage.getItem("activemmId")!=undefined){
  var Id  =  localStorage.getItem("activemmId");
  
  document.getElementById("men"+Id).classList.remove("hidden");
  document.getElementById("mem"+Id).classList.add("ribbon-button-selected");
  }

  else {

    this.setState({activemmId : 3});
    document.getElementById("men"+this.state.activemmId).classList.remove("hidden");
    document.getElementById("mem"+this.state.activemmId).classList.add("ribbon-button-selected");
  }
  }

  test  =  (Id)  =>(event) =>{
    

   if (this.state.activemmId!=undefined){
     if (this.state.activemmId==Id){


      
      
    //   if (document.getElementById("men"+Id).style.visibility == 'visible'){
    //     //document.getElementById("men"+Id).style.visibility = "hidden";
       
    //     }
    //     else{
      
    //     document.getElementById("men"+Id).classList.remove("hidden");
        
    //     document.getElementById("mem"+Id).classList.add("ribbon-button-selected");

    //     document.getElementById("men"+this.state.activemmId).classList.add("hidden");
    //     document.getElementById("mem"+this.state.activemmId).classList.remove("ribbon-button-selected");
   
       
    //   }
    }

     else {


      document.getElementById("mem"+this.state.activemmId).classList.remove("ribbon-button-selected");
      document.getElementById("men"+this.state.activemmId).classList.add("hidden");

      document.getElementById("mem"+Id).classList.add("ribbon-button-selected");
      document.getElementById("men"+Id).classList.remove("hidden");


     
   }
   }
   
    this.setState({ activemmId: Id });
    localStorage.setItem('activemmId',Id);
  }

  


  render() {
   
    const obj = JSON.parse(localStorage.getItem("authUser"));
    const PageName   =  this.state.PageName.toUpperCase();
           

  





    return (
      <React.Fragment>
          
          <div className="ribbon" style={{width : this.state.windowwidth}} >

            <div style={{height:'20px'}}>
            <div id="name" style={{color:'white', marginLeft:"10px", float:'left'}}>{obj.role}: {obj.username}</div>

            <div  style={{color:'white', marginRight:'1%', float:'right'}}><button
                  type="button"
                  style={{marginLeft:10}}
                  onClick={this.toggleFullscreen}
                  data-toggle="fullscreen"
                >
                  <i className="bx bx-fullscreen" />
                </button></div>
           
            <Link style={{color : 'white'}} to="/logout"><div id="name" style={{color:'white', marginRight:'1%', float:'right'}} >
               Sign Out</div></Link>

               <Link style={{color : 'white'}} to="/dashboard">
               <div  style={{color:'white', marginRight:'1%', float:'right'}}><button
                  type="button"
                  style={{marginLeft:10}}
              
                  data-toggle="fullscreen"
                >
                  Dashboard
                </button>
                </div>
                </Link>
            </div>

<div style={{display: 'flex'}}>


{obj.roleid  ==  4  ? 
<div id="mem1" className="ribbon-tab " onClick={this.test(1)}>Master</div>
: null}

<div id="mem2" className="ribbon-tab " onClick={this.test(2)}>Member</div>
<div id="mem3" className="ribbon-tab " onClick={this.test(3)}>Transact</div>

{obj.roleid == 4 || obj.roleid == 3? 
<div id="mem4" className="ribbon-tab" onClick={this.test(4)}>Accounts</div>

: null}

{obj.roleid == 4 || obj.roleid == 3 ? 
<div id="mem5" className="ribbon-tab" onClick={this.test(5)}>Loans</div>

: null}

{obj.roleid == 4 || obj.roleid == 3? 
<>
<div id="mem6" className="ribbon-tab " onClick={this.test(6)}>Modifications</div>
<div id="mem7" className="ribbon-tab" onClick={this.test(7)}>Agent</div>

<div id="mem8" className="ribbon-tab" onClick={this.test(8)}>Staff</div>

<div id="mem9" className="ribbon-tab" onClick={this.test(9)}>Operations</div>

</>
: null}

{obj.roleid == 4 || obj.roleid == 3 ? 
<div id="mem10" className="ribbon-tab" onClick={this.test(10)}>Self-Service</div>
: null}

{obj.roleid == 4 || obj.roleid == 3 ?
<div id="mem11" className="ribbon-tab" onClick={this.test(11)}>Management</div>
: null}

<div id="mem12" className="ribbon-tab" onClick={this.test(12)}>Help</div>


</div>


</div>


<div className="menus hidden" id="men1">
<div className="rowheader">
  <div className="elements">Members</div>
  <div className="elements">Numbering</div>
  <div className="elements">Accounts</div>


  <div className="elementsheading">REGISTRATION</div>
</div>

<div className="rowheader">
  <div className="elements">Fees</div>
  <div className="elements">Interest</div>
  <div className="elements">Penalties</div>

  <div className="elementsheading">FEE SCHEDULE</div>
</div>

<div className="rowheader">
  <div className="elements">Scheme</div>
  <div className="elements">Hire-Purchases</div>
  <div className="elements">Mortgage</div>

  <div className="elementsheading">LOAN</div>
</div>

<div className="rowheader">
<Link to="/schemeinterestmaster" className="has-arrow">
  <div className="elements">Scheme Interest</div>
  </Link>
  <div className="elements">Financial Lease</div>

  <Link to="/masters-id-document-type" className="has-arrow">
  <div className="elements">Document Master</div>
  </Link>
  
</div>

<div className="rowheader">
  <div className="elements">Reverse Mortgage</div>

  
  <div className="elements">Staff Register</div>

  <div className="elements">Pay-Scale</div>

  <div className="elementsheading">STAFF</div>
</div>

<div className="rowheader">
  <div className="elements">Access Rights</div>
  <div className="elements">Share Issues</div>
  <div className="elements">Dividend Rates</div>

  <div className="elementsheading">SHARES</div>
</div>

<div className="rowheader">
  <div className="elements">Pay-out Date</div>
  <div className="elements">Document Headings</div>
  <div className="elements">Records</div>

  <div className="elementsheading">MANAGEMENT</div>
</div>

<div className="rowheader">
<Link to="/masters-designation" className="has-arrow">
  <div className="elements">Job Title Master</div>
  </Link>

  <Link to="/masters-dutymode" className="has-arrow">
  <div className="elements">Duty Mode</div>
  </Link>


  <Link to="/masters-organisation" className="has-arrow">
  <div className="elements">Organisation Master</div>

  </Link>


  <div className="elementsheading">SELF-SERVICE</div>
</div>





<div className="rowheader">
<Link to="/masters-subjectmaster" className="has-arrow">
  <div className="elements">Subject Master</div>
  </Link>

   <Link to="/globaloptions" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Global Setting</div>
  </Link>


  <div className="elementsheading">SUPPPORT</div>
</div>


</div>

<div className="menus hidden" id="men2">
<div className="rowheader">

 



  {obj.roleid == 4 || obj.roleid == 3 || obj.roleid  == 1 || obj.roleid  == 2 ? 

  <>
  <Link to="/add-membership" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Member Registration</div>
  </Link>
 


  <Link to="/add-entity-membership" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Entity Registration</div>
</Link>

</>

: null}

{obj.roleid == 4 || obj.roleid == 3  ? 
<Link to="/provisionalmember" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Provisional Member</div>
 </Link>
  : null}


{obj.roleid == 4 || obj.roleid == 3 ? 
<Link to="/unapproved" className="has-arrow">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Approve Entrance</div>
  </Link>
   : null}

  <div className="elementsheading">REGISTRATION</div>
</div>

{obj.roleid == 4 || obj.roleid == 3? 

<>
<div className="rowheader">



<Link to="/memberships" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Members Register</div>
  </Link>

  <Link to="/membersynopsispage" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Member Synopsis</div>
  </Link>

  {obj.roleid == 4 || obj.roleid == 3 ?
  <Link to="/membersforstatement" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Member Statement</div>
</Link>
: null}

<Link to="/memberjoiningreport" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Member Joining Report</div>
  </Link>

  <div className="elementsheading">MEMBER RECORDS</div>
</div>
</>
: null}


{obj.roleid == 4 || obj.roleid == 3? 

<>
<div className="rowheader">





{obj.roleid == 4 || obj.roleid == 3  ?
  <Link to="/nonmembers" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Non-Members Register</div>
  </Link> : null}

  <Link to="/deadregister" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Dead Members Register</div>
</Link>

<Link to="/personaregister" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Persona Non-Gratae</div>
</Link>


<Link to="/memberstatistics" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Statistics</div>
</Link>

  {/* <Link to="/memberships" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Search for Member</div>
</Link> */}


  <div className="elementsheading">Non-Member Records</div>
</div>

</> : null}



</div>








<div className="menus hidden" id="men3">
<div className="rowheader">


{obj.roleid == 4 || obj.roleid == 3 ? 
<Link to="/checkbalance" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Check Balance</div>
  </Link> : null}



 

  <Link to="/add-cashdeposit" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Cash Deposit</div>
  </Link>
    

  <Link to="/withdrawrequest" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Cash Withdrawal</div>
  </Link>


  <div className="elementsheading">BALANCE</div>
</div>


{obj.roleid == 4 || obj.roleid == 3? 

<>

<div className="rowheader">
<Link to="/accounttoaccount" className="has-arrow">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Fund transfer from Transaction Account</div>
  </Link>

  <Link to="/accounttoaccount" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Fund transfer to Transaction Account</div>
</Link>

<Link to="/persontoperson" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Person to Person fund transfer</div>
</Link>
  <div className="elementsheading">FUNDS</div>
</div>

</> : null}


{obj.roleid == 4 || obj.roleid == 3 ? 
<>

<div className="rowheader">



<Link to="/cashbook" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Cash Book</div>
  </Link> 



  <Link to="/ledgerdetails" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Ledger</div>
  </Link>

  
  <Link to="/journalvouchers" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>DEBIT/CREDIT</div>
  </Link>
 

  <div className="elementsheading">Reports</div>
</div>

</> : null}



{obj.roleid == 4 || obj.roleid == 3 ? 
<>

<div className="rowheader">



<Link to="/addshare" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Share Purchase</div>
  </Link> 



 
 

  <div className="elementsheading">Shares</div>
</div>

</> : null}


</div>






<div className="menus hidden" id="men4">
<div className="rowheader">

<Link to="/account_transaction" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Transaction Account</div>
  </Link>
  <Link to="/reserved_transaction" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Reserved Account</div>
  </Link>

  <Link to="/monthlyincome_transaction" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Monthly Income Scheme</div>
  </Link>

  <div className="elementsheading">ACCOUNTS</div>
</div>

<div className="rowheader">

<Link to="/membersharestatement" className="has-arrow">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Share Statement</div>
  </Link>

  <Link to="/caveocardstatement" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Caveo Card Account</div>
  </Link>

  <Link to="/thrift_transaction" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Thrift Account</div>
  </Link>

  <div className="elementsheading"></div>
</div>

<div className="rowheader">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Term Deposit Account</div>

 

  <div className="elementsheading"></div>
</div>


<div className="rowheader">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Death Compensation Assurance</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Co-Operative Insure</div>
  {/* <Link to="/globaloptions" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Global Setting</div>
  </Link> */}
  <div className="elementsheading">FIDUCIARY</div>
</div>

</div>


<div className="menus hidden" id="men5">


<div className="rowheader">

  <div className="scrollingcontent">

  <Link to="/loanstatement" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>View Account</div>
  </Link>
  <Link to="/creditloan" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Create New Account</div>
  </Link>

  <Link to="/cashloan" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Approve Loan</div>
  </Link> 
  <Link to="/rejectedloan" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Rejected Loan</div>
  </Link>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Recovery Due Report</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Loan Register</div>
  <Link to="/cashloanapproved" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Approved Loan</div>
  </Link>


  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Discharged Loans Register</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Delinquet Loans Register</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Current Loans Register</div>
  </div>
  
  <div className="elementsheading">CASH LOAN</div>
</div>

<div className="rowheader">
<div className="scrollingcontent">

 <Link to="/loanstatement" className="has-arrow">
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>View Account</div>
</Link>


<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Create New Document</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Approve Document</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Close Account</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Status Report</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Collection Due Report</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Recovery Due Report</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Documentary Credit Register</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Discharged Documents Register</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Current Documents Register</div>

<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Documentary Letter of Credit</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Stand by Letter of Credit</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Guarantee</div>

</div>
  <div className="elementsheading">DOCUMENT CREDIT</div>
</div>

<div className="rowheader">
<div className="scrollingcontent">


<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>View Account</div>

<Link to="/mortgage" className="has-arrow">
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Create New Mortgage</div>
</Link>


<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Approve Mortgage</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Close Account</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Collection Due Report</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Recovery Due Report</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Mortgage Register</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Discharged Mortgage Register</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Current Mortgage Register</div>

</div>
  <div className="elementsheading">MORTGAGE</div>
</div>


<div className="rowheader">
<div className="scrollingcontent">


<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>View Account</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Create New Reverse Mortgage</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Approve Reverse Mortgage</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Close Account</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Collection Due Report</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Recovery Due Report</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Reverse Mortgage Register</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Reverse Discharged RM Register</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Current RM Register</div>

</div>
  <div className="elementsheading">REVERSE MORTGAGE</div>

</div>


<div className="rowheader">


<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Status Report</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Member Utility Report</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Loan Business Report</div>

  <div className="elementsheading">REPORTS</div>

</div>

</div>




<div className="menus hidden" id="men6">
<div className="rowheader">

  <Link to="/masters-membershipmodify" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Member Records</div>
  </Link>
  <Link to="/memberupdaterequest" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Process Self-Service</div>
  </Link>

  <Link to="/monthlyincome_transaction" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Process Agent Request</div>
  </Link>

  <div className="elementsheading">MEMBER</div>
</div>

<div className="rowheader">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Transaction Account</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Shares</div>
  
  <Link to="/thrift_transaction" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Caveo Card Account</div>
</Link>


  <div className="elementsheading">ACCOUNTS</div>
</div>

<div className="rowheader">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Reserved Account</div>

  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Thrift Account</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Monthly Income Scheme</div>
 

  <div className="elementsheading"></div>
</div>


<div className="rowheader">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Term Deposit</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Death Compensation Assurance</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Co-Operative Insure</div>

  <div className="elementsheading"></div>
</div>


<div className="rowheader">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Cash Loan</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Credit</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Document Credit</div>

  <div className="elementsheading">LOANS</div>
</div>

<div className="rowheader">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Mortgage</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Reverse Mortgage</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Archieve</div>

  <div className="elementsheading"></div>
</div>

</div>

<div className="menus hidden" id="men7">
<div className="rowheader">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Agent Service Portal</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Add Funds to Agent Account</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Transfer Funds from Agent Account</div>
  <div className="elementsheading">AGENt</div>
</div>

<div className="rowheader">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Agent Cash Book</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Agent Statement</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>.</div>

  <div className="elementsheading">REPORTS</div>
</div>

</div>


<div className="menus hidden" id="men8">
<div className="rowheader">

<Link to="/add-Agent" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Add New Staff</div>
  </Link>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Add Removed </div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Remove Staff</div>


  <div className="elementsheading">STAFF</div>
</div>

<div className="rowheader">
<Link to="/agentdetails" className="has-arrow">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Staff Register</div>
  </Link>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Process Salaries</div>

  {obj.roleid == 4 ? 
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Approve Salaries</div>

  : null}

  <div className="elementsheading">SALARY</div>
</div>

<div className="rowheader">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Statement of Payments</div>
  <div className="elementsheading">REPORTS</div>
</div>


</div>


<div className="menus hidden" id="men9">
<div className="rowheader">

{obj.roleid == 4 || obj.roleid == 3 ?
<>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Register Agent</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Approve Agent</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Agent Register</div>
</>
: null}

  <div className="elementsheading">AGENT</div>
</div>

<div className="rowheader">
{obj.roleid == 4 || obj.roleid == 3 ?
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Manage Agent</div>
: null}
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Agent Leader</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Agent Cash Book</div>

  <div className="elementsheading"></div>
</div>

<div className="rowheader">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Agent Log</div>

</div>

<div className="rowheader">

{obj.roleid == 4 || obj.roleid == 3 ?

<>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Public User Registration</div>

  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Create Public Viewer</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Public Viewer Log</div>
 </> : null}

  <div className="elementsheading">PUBLIC VIEWER</div>
</div>


</div>



<div className="menus hidden" id="men10">
<div className="rowheader">


  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>My Statement</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>My Memborship Synopsis</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Inter-Account Transfer</div>


  <div className="elementsheading">TRANSACTIONS</div>
</div>

<div className="rowheader">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>My Transaction Account to Another</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Standing Order</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Request to Reversal of Payments</div>

  <div className="elementsheading"></div>
</div>

<div className="rowheader">
<Link to="/joblist" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>My Applications</div>
  </Link>

</div>


</div>


<div className="menus hidden"  id="men11">
<div className="rowheader">


  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Control Panel</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Budget</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Membership Data</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Agent Records</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Salary Payment</div>


</div>

<div className="rowheader">
<Link to="/managementtransaction" className="has-arrow">
  <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Transaction Account</div>
  </Link>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Caveo Card Account</div>
  
 
  <Link to="/managementreserved" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Reserved Account</div>
</Link>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Thrift Account</div>
<Link to="/managementmis" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Monthly Income Scheme</div>
</Link>

  <div className="elementsheading"></div>
</div>

<div className="rowheader">
<div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Death Compensation Assurance</div>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Co-Operative Insure</div>
<Link to="/voucherreceived" className="has-arrow">
<div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Vouchers Received</div>

</Link>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Vouchers Reserved</div>

<Link to="/voucherpaid" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Vouchers Paid</div>
</Link>


</div>


<div className="rowheader">
  
<Link to="/managementsharestatement" className="has-arrow">
<div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Shares Account</div>
</Link>
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Divident Sesitivity Analysis</div>
  
<div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Assets</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Portfolio</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Bullion Amount</div>

</div>





<div className="rowheader">
<Link to="/IncomeStatement" className="has-arrow">
<div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Income Statement</div>
</Link>

<Link to="/cashflowstatement" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Cash Flow Statement</div>
  </Link>

  <Link to="/balancesheet" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Balance Sheet</div>
</Link>

<Link to="/managemententrancefee" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Activation Fee</div>
</Link>


</div>





</div>


<div className="menus hidden" id="men12">
<div className="rowheader">

<Link to="/helptopics" className="has-arrow">
<div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Help Topics</div>
</Link>
<Link to="/tickets" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Support Contact</div>
  </Link>

 
<Link to="/unpaidmember" className="has-arrow">
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Activate Account</div>
</Link>

  

  {/* <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Access and Outlook</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Member</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Transact</div> */}

</div>

<div className="rowheader">
  {/* <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Accounts</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Loans</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Modifications</div> */}

  <div className="elementsheading"></div>
</div>

<div className="rowheader">
  {/* <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Agent</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Staff</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Operations</div>
 */}

</div>

<div className="rowheader">
  {/* <div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Self-Service</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Managament</div>
  <div className="elements"><i className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Help Contact</div> */}


</div>

<div className="rowheader">
{/* 
<Link to="/globaloptions" className="has-arrow">
<div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Global Options</div>
</Link>

<Link to="/maillist" className="has-arrow">
<div className="elements"><i  className="bx bx-calendar" style={{marginRight:"3px", color:'#2151a4'}}/>Support Mails</div>
</Link> */}
 

</div>


</div>


<div className="buttons">
    <div className="buttonsdiv">
        <img src={save} height={35}/>
    </div>
    <div className="buttonsdiv">
        <img src={news} height={35}/>
    </div>
    <div className="buttonsdiv">
        <img src={reset} height={35}/>
    </div>
    <div className="buttonsdiv">
        <img src={print} height={35}/>
    </div>

    <div className="buttonsdiv">
        <img src={exports} height={35}/>
    </div>
   
    <div className="buttonsdiv">
        <img src={send} height={35}/>
    </div>

    <div className="buttonsdiv2">
        {this.state.PageName}
    </div>
</div>




   
    
      </React.Fragment>
    )
  }
}

Header.propTypes = {
  openLeftMenuCallBack: PropTypes.func,
  t: PropTypes.any,
  toggleRightSidebar: PropTypes.func
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout
  return { layoutType }
}

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(Header)
)
