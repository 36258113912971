import React, { Component } from "react";

//Import Breadcrumb
import { Row, Col, Card, CardBody, CardTitle, Button , Modal} from "reactstrap"
//Constants
import { API_WEB_URLS } from "../../constants/constAPI";
//Store
import { compose } from "recompose";
import { container } from "../../store/Containers/cntCommon";
import {
  Fn_FillListData,
  togglemodal,
  toggleDeleteConfirm,
  toggleDeleteSuccess,
  Fn_DeleteData,
  Fn_ChangeStateValue
} from "../../store/functions";
import "./datatables.scss"
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider, PaginationListStandalone,
  SizePerPageDropdownStandalone
} from 'react-bootstrap-table2-paginator';
import SweetAlert from "react-bootstrap-sweetalert";

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { API_HELPER } from "helpers/ApiHelper";
import  Switch  from "react-switch";



class pageList_ProvisionalMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      selectedFormData: {},
      gridData: [],
      confirm_alert: false,
      success_dlg: false,
      dynamic_title: "",
      dynamic_description: "",
      page: 1,
      sizePerPage: 10,
      productData: [],
      commissionmodal : false,
      success_msg : false
    };
    this.obj = this;
    this.breadCrumbTitle = "Masters";
    this.breadCrumbItem = "Provisional Member";
    this.modalTitle = "Provisional Member";
    this.rtPage_Add = "AddLedger";
    this.rtPage_Edit = "edit-memberapprove";
    this.API_URL = API_WEB_URLS.MASTER + "/0/token/member";

    //Event Binding
    this.btnAdd_onClick = this.btnAdd_onClick.bind(this);
    this.btnEdit_onClick = this.btnEdit_onClick.bind(this);
    this.btnDelete_onClick = this.btnDelete_onClick.bind(this);
    this.approve =  this.approve.bind(this);
    this.syno =  this.syno.bind(this);
  }
  componentDidMount() {

    localStorage.removeItem("PageName")
    localStorage.setItem("PageName", "Provisonal Member")
    const obj = JSON.parse(localStorage.getItem("authUser"));
     Fn_FillListData(this.obj, "productData", this.API_URL + "/Id/8" );
     Fn_FillListData(this.obj, "membershipType", API_WEB_URLS.MASTER + "/0/token/MembershipType/Id/0");
  }
  btnAdd_onClick(event, values) {
    this.props.history.push(`${this.rtPage_Add}`);
  }
  btnEdit_onClick(Id) {
      //this.setState({MemberId : Id, commissionmodal : true})

    this.props.history.push(`${this.rtPage_Edit}/${Id}/1`, {});
  }
  btnDelete_onClick(Id) {
    Fn_DeleteData(this.obj, Id, "DeleteMember/0/token", this.API_URL + "/Id/0");
  }
  // renderGridHeader() {
  //   return (
  //     <>
  //       <th>Name</th>
  //       <th>Ledger Group Name</th>
  //       <th>Phone</th>
  //       <th>Email</th>
       
  //     </>
  //   );
  // }

  approve  = async()=>{

    let vformData = new FormData();
   
  //Information
  vformData.append("Id", this.state.MemberId);
  vformData.append("F_MembershipType", 4);
  vformData.append("LoginPin", this.state.Pin);

    let AuthUser2 = function() {
      return API_HELPER.apiPOST_Multipart(API_WEB_URLS.BASE+"ApproveProvisionalMember/0/token" , vformData).then(token => { return token } )
    }

    let userToken2 = await AuthUser2();

    this.setState({success_msg : true , MemberId : 0 , MembershipTypeId : 0,Pin : '' })
    Fn_FillListData(this.obj, "productData", this.API_URL + "/Id/8" );
  }

  syno(){
    this.setState({success_msg : false, commissionmodal : false })
  }
  
  render() {

    

    const columns = [
      {
        dataField: "VERIFY ",
        text: "VERIFY ",
        formatter: (cellContent, row) => {
          return (
            <button
            type="button"
              className="btn btn-success btn-xs"
              onClick={() => this.btnEdit_onClick(row.Id)}
            >
           
              VERIFY 
            </button>
          );
        }
      },
      {
      dataField: 'MembershipNo',
      text: 'MEMBERSHIP NO',
      sort: true
    },
     {
      dataField: 'Name',
      text: 'MEMBERS NAME',
      sort: true
    },
    {
      dataField: 'GenderId',
      text: 'SEX',
      sort: true,
      formatter: (cell, row) => (
        <span >{row.GenderId == 2 ? 'Female' :  row.GenderId == 1 ? 'Male' : row.GenderId == 3 ? 'Entity' : 'not defined'}</span>
      )
    },
     {
      dataField: 'Address_Residential',
      text: 'ADDRESS',
      sort: true
    },
   {
    dataField: 'MobileNo_SMS',
    text: 'PHONE',
    sort: true
  },
  {
    dataField: 'Delete',
    text: 'Delete',
    sort: false,
    formatter: (cellContent, row) => {
      return (
        <button
        type="button"
          className="btn btn-success btn-xs"
          onClick={() => this.btnDelete_onClick(row.Id)}
        >
          Delete 
        </button>
      );
    }

  }
  
  // ,

  // {
  //   dataField: 'PhoneNo1_Nominee',
  //   text: 'EMERGENCY CONTACT',
  //   sort: true
  // }


];


    const defaultSorted = [{
      dataField: 'id',
      order: 'asc'
    }];
  
    const pageOptions = {
      sizePerPage: 10,
      totalSize: this.state.productData.length, // replace later with size(customers),
      custom: true,
    }
  
    // Custom Pagination Toggle
    const sizePerPageList = [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '15', value: 15 },
      { text: '20', value: 20 },
      { text: '25', value: 25 },
      { text: 'All', value: (this.state.productData).length }];
   
    const { SearchBar } = Search;


    


    return (
      <div className="page-content" style={{marginTop:150}}>
<Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={this.state.productData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={this.state.productData}
                          search
                        >
                          
                          {toolkitProps => (
                            <React.Fragment>

                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                     // selectRow={selectRow}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />

                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )
                          }
                        </ToolkitProvider>
                      )
                      }</PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
              </Row>




<Modal
                        isOpen={this.state.commissionmodal}
                        toggle={this.commissionmodal}
                        scrollable={true}
                        backdrop={'static'}
                        id="staticBackdrop2"
                      >
                        <div className="modal-header">
                          <h5 className="modal-title" >Approve Member</h5>
                          <button type="button" className="btn-close" onClick={() =>
                            this.setState({ commissionmodal: false })
                          } aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        {/* <Row>
                        <Col sm="6" className="mb-3">
                                          <label htmlFor="firstName" className="col-form-label">Membership Type</label>
                                        </Col>
                                        <Col sm="4" className="mb-3">

                                        <select name="MembershipTypeId" label="" value={this.state.MembershipTypeId} onChange={(e) => Fn_ChangeStateValue(this.obj, 'MembershipTypeId', e.target.value)}  errorMessage="Select Membership Type" validate={{ required: { value: true } }} type="select" className="form-select" >
                                            <option value={-1} defaultValue label={"Select"} />
                                            {this.state.membershipType
                                              ? this.state.membershipType.map(
                                                  (option, key) => (
                                                    <option key={option.Id} value={option.Id} label={option.Name} />
                                                  ) 
                                                )
                                              : null}
                                          </select>
                         
                         </Col>
                         </Row> */}

                         {this.state.MembershipTypeId  == 4 || this.state.MembershipTypeId  == 7 || this.state.MembershipTypeId  == 8 ||this.state.MembershipTypeId ==undefined  ? 
                         
                         null : <Row>
                         <Col sm="6" className="mb-3">
                                           <label htmlFor="firstName" className="col-form-label">Login PIN</label>
                                         </Col>
                                         <Col sm="4" className="mb-3">
                           <input name="Pin" label="Pin" value={this.state.Pin}  onChange={(e) => Fn_ChangeStateValue(this.obj, 'Pin', e.target.value)}  placeholder="Enter Pin"   type="number"  className="form-control" />
                          </Col>
                          </Row>
                         }
                        
                         
                       
                       
                        </div>
                        <div className="modal-footer">
                          <button type="button" onClick={this.approve} className="btn btn-info"  >Approve</button>
                         
                        </div>
                      </Modal>

                      {this.state.success_msg == true ? (
                      <SweetAlert
                        title="Approved Succesfully !"
                        success
                       
                        confirmBtnBsStyle="success"
                      
                        onConfirm={this.syno}
                        
                      >
                      
                      </SweetAlert>
                    ) : null}

      </div>
    );
  }
}
export default compose(container)(pageList_ProvisionalMember);