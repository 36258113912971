import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
//Constants
import {API_WEB_URLS} from '../constants/constAPI';
import * as base_acTypes from './actionTypes';
/** Common Functions **/
//Change state value
export const Fn_ChangeStateValue = (obj, name, value) => {

    obj.setState({[name]: value});
};

export const Fn_FillListData = (obj,gridName,apiURL) => {
    const request = {
        apiURL: apiURL,
        callback: (response) => {
            if (response && response.status === 200 && response.data) {
                obj.setState({
                    [gridName]: response.data.dataList,
                    isProgress : false
                })
            } else {
                showToastWithCloseButton("error", "Some error occurred while displaying grid data");
            }
        },
    };
    obj.props.callFill_GridData(request)
};



export const Fn_GetReport = (obj,data,apiURL,gridName,isMultiPart=false) => {    
    const { arguList } = data;
    const request = {
        arguList: arguList,
        apiURL: apiURL,
        callback: (response) => {
            if (response && response.status === 200 && response.data) {

                if (gridName == 'gridDatanominal'){
                    obj.setState({
                        MainArr: response.data.response,
                    })
                }
                   
                
                obj.setState({
                        [gridName]: response.data.response,
                        isProgress : false
                    })
            } else {
                showToastWithCloseButton("error", "Some error occurred.");
            }
        },
    };
    
    if (arguList.id === 0)
 
        if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
        else obj.props.callAdd_Data(request);
    else
        if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
        else obj.props.callEdit_Data(request);
   
};



export const Fn_DisplayData = (obj,id,apiURL) => {
    const request = {
        id:id,
        apiURL: apiURL,
        callback: (response) => {
            if (response && response.status === 200 && response.data) {                
                obj.setState({
                    formData: response.data.dataList[0]
                })

               

                if (apiURL  ==  'Masters/0/token/MemberEdit/Id'){
                    obj.setState({
                        selectedGroup: {id : response.data.dataList[0].F_BankId, label :response.data.dataList[0].BankName },
                        F_BankId : response.data.dataList[0].F_BankId
                    })
                   
                }
                // if(apiURL===API_WEB_URLS.COMPANY)
                // {
                //     //Fill city drop down
                //     if(response.data.data[0].stateId && response.data.data[0].stateId>0)
                //         Fn_FillListData(obj,'lstCity',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/cities/stateId/'+ response.data.data[0].stateId);
                // }
                // else if(apiURL===API_WEB_URLS.PROPERTY_FLOOR || apiURL===API_WEB_URLS.SUB_COMPANY)
                // {
                //     //Fill city drop down
                //     if(response.data.data[0].blockId && response.data.data[0].blockId>0)
                //         Fn_FillListData(obj,'lstBlock',API_WEB_URLS.COMMON_GETLIST_DROPDOWN + '/blocks/propertyId/'+ response.data.data[0].propertyId);
                // }
            } else {
                showToastWithCloseButton("error", "Some error occurred while displaying data");
            }
        },


        
    };
    obj.props.callGet_Data(request)
};






export const Fn_AddEditData = (obj, data,apiURL,pushFormName,isMultiPart=false , getid, fun) => {    
    const { arguList } = data;
    const request = {
        arguList: arguList,
        apiURL: apiURL,
        callback: (response) => {

            if (response && response.status === 200) {

             
               
                obj.setState({
                    [getid]: response.data.data.id,
                } , () => {
                    fun();
                })

                

                if (arguList.id === 0){
                    showToastWithCloseButton("success", "Data added successfully");
                    obj.setState({
                        success_msg : true
                    })
                }
                else
                    showToastWithCloseButton("success", "Data updated successfully");
                obj.props.history.push(pushFormName);
            } else {

                if (response.data.data.id  ==  -1 || response.data.data.id == "-1"){
                    obj.setState({
                        duplicate : true
                    });
                }
                else {

                if (arguList.id === 0)
                    showToastWithCloseButton("error", "Some error occurred while updating data");
                else
                    showToastWithCloseButton("error", "Some error occurred while updating data");
                }
                
            }
        },
    };
   
    if (arguList.id === 0)
 
        if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
        else obj.props.callAdd_Data(request);
    else
        if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
        else obj.props.callEdit_Data(request);
};





export const Fn_AddEditDataN = (obj, data,apiURL,pushFormName,isMultiPart=false , getid) => {    
    const { arguList } = data;
    const request = {
        arguList: arguList,
        apiURL: apiURL,   
        callback: (response) => {

            if (response && response.status === 200) {
                obj.setState({
                    [getid]: response.data.data.id,
                } 
                )

                if (arguList.id === 0){
                    showToastWithCloseButton("success", "Data added successfully");
                    obj.setState({
                        success_msg : true
                    })
                }
                else
                    showToastWithCloseButton("success", "Data updated successfully");
                obj.props.history.push(pushFormName);
            } else {
                if (response.data.data.id  ==  -1 || response.data.data.id == "-1"){
                    obj.setState({
                        duplicate : true
                    });
                }

                else {
                if (arguList.id === 0)
                    showToastWithCloseButton("error", "Some error occurred while updating data");
                else
                    showToastWithCloseButton("error", "Some error occurred while updating data");
                }
                
            }
        },
    };
   
    if (arguList.id === 0)
 
        if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
        else obj.props.callAdd_Data(request);
    else
        if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
        else obj.props.callEdit_Data(request);
};


export const Fn_ChangePassword = (obj,data,apiURL,pushFormName,isMultiPart=false) => {    
    const { arguList } = data;
    const request = {
        arguList: arguList,
        apiURL: apiURL,
        callback: (response) => {

            if (response.data.response[0].Id>0) {
                if (arguList.id === 0)
                    showToastWithCloseButton("success", "Password Changed Successfully");
                else
                    showToastWithCloseButton("success", "Data updated successfully");
                obj.props.history.push(pushFormName);
            } else {
                if (arguList.id === 0)
                    showToastWithCloseButton("error", "Old Password is wrong.");
                else
                    showToastWithCloseButton("error", "Some error occurred while updating data");
            }
        },
    };
   
    if (arguList.id === 0)
 
        if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
        else obj.props.callAdd_Data(request);
    else
        if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
        else obj.props.callEdit_Data(request);
};

export const Fn_TransactionWithdraw = (obj,data,apiURL,pushFormName,isMultiPart=false , getid , fun) => {    
    const { arguList } = data;
    const request = {
        arguList: arguList,
        apiURL: apiURL,
        callback: (response) => {
            if (response && response.status === 200 && response.data) {

                if (response.data.response[0].Id == 0){
                    obj.setState({
                        unsuccess_msg : true,
                        isProgress : false
                    }) 
                }
                else {
                obj.setState({
                    [getid]: response.data.response[0].Id,
                    success_msg: true,
                    isProgress : false,
                    fo : '',
                    formData : {
                        'AccountValue' : 0,
                        'membershipno' : '',
                        'MembershipTypeId' : 0,
                        'Amount' : '',
                    }
                    
                }, () => {
                    fun();
                })
              
            }
            } else {
                showToastWithCloseButton("error", "Some error occurred.");
            }
        },
    };
    obj.props.callAdd_Data_Multipart(request);
   
};





export const Fn_UserLogin = (obj,data,apiURL,pushFormName,isMultiPart=false) => {    
  
    const { arguList } = data;
    const request = {
        arguList: arguList,
        apiURL: apiURL,
        callback: (response) => {
        
            if (response && response.status === 200) {
                if (arguList.id === 0)
                    showToastWithCloseButton("success", "Logged in successfully");
                else
                    showToastWithCloseButton("success", "Logged in successfully");
                obj.props.history.push(pushFormName);
            } else {
                if (arguList.id === 0)
                    showToastWithCloseButton("error", "UserName or Password Incorrect!");
                else
                    showToastWithCloseButton("error", "UserName or Password Incorrect!");
            }
        },
    };
  
    if (arguList.id === 0)
        if (isMultiPart) obj.props.callAdd_Data_Multipart(request);
        else obj.props.callAdd_Data(request);
    else
        if (isMultiPart) obj.props.callEdit_Data_Multipart(request);
        else obj.props.callEdit_Data(request);
};

export const Fn_DeleteData = (obj,id,apiURL,apiURL_Display) => {    
    const request = {
        id:id,
        apiURL: apiURL,
        callback: (response) => {
            if (response && response.status === 200) {
                obj.setState({
                    confirm_alert: false,
                    success_dlg: true,
                    dynamic_title: "Deleted",
                    dynamic_description: "Selected data has been deleted.",
                })
                showToastWithCloseButton("success", "Data deleted successfully");
                if(apiURL_Display) {               
                Fn_FillListData(obj,'gridData',apiURL_Display);
                Fn_FillListData(obj,'productData',apiURL_Display);
                }
            }
            else{
                obj.setState({
                    confirm_alert: false,
                    dynamic_title: "Deleted",
                    dynamic_description: "Selected data has been deleted.",
                })
                showToastWithCloseButton("error", "Some error occurred while deleting data");
            }
        },
    };
    obj.props.callDelete_Data(request);
};

export const togglemodal = (obj,formData) => {
    obj.setState(prevState => ({
        modal: !prevState.modal,
        selectedFormData: formData ? formData : {}
    }));
}




export const toggleDeleteConfirm = (obj,formData,value) => {
    obj.setState({confirm_alert: value,
        selectedFormData: formData ? formData : {}
    });
}

export const toggleapprove = (obj,formData,value) => {
    obj.setState({confirmapprove_alert: value,
        selectedFormData: formData ? formData : {}
    });
}

export const toggleDeleteSuccess = (obj,value) => {
    obj.setState({success_dlg: value});
}

function showToastWithCloseButton(toastType, message) {
    toastr.options = {
        closeButton: true,
        preventDuplicates: true,
        newestOnTop: true,
        progressBar: true,
        timeOut: 2000
    }
    if (toastType == "success")
        toastr.success(message);
    else if (toastType == "error")
        toastr.error(message);
}
/***/
